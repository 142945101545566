import React from "react"
import { asModal } from "../lib/ui"

const ModalPage = ({ modal, closeTo }) => {
    return(
        <div>
            Hello World, from modal
        </div>
    )
}

export default asModal(ModalPage)